import React, { useState } from 'react';
import { db } from '../firebase';

const PdfDisponibles = () => {
	const [docs, setDocs] = useState([]);

	useState(() => {
		getUsuarios();
	}, []);

	function getUsuarios() {
		db.collection('PdfDisponibles').onSnapshot((querySnapshot) => {
			const documents = [];
			querySnapshot.forEach((doc) => {
				documents.push({...doc.data(), id: doc.id});
			});
			setDocs(documents);
		});
	}

	return (
		<div className="pdfdisponibles page">
			<div className="grid-container">
				<div className="grid-row-header">
					<h1>Capitulo</h1>
					<h1>Categoria</h1>
					<div>
					</div>
				</div>
				{docs.map(doc => <div className="grid-row" key={doc.id}>
					<h1>{doc.capitulo}</h1>
					<h1>{doc.categoria}</h1>
					<div className="grid-action">
						<i className="fa fa-pencil"></i>
					</div>
				</div>)}
			</div>
		</div>
	)
}

export default PdfDisponibles;

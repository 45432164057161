import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { db } from '../firebase';

import Modal from '../components/Modal';

const PrecioSuscripciones = () => {
	const [nombre, setNombre] = useState("");
	const [docs, setDocs] = useState([]);
	const [show, setShow] = useState(false);
	const [selected, setSelected] = useState({});

	useState(() => {
		getPrecioSuscripciones();
	}, []);

	function getPrecioSuscripciones() {
		db.collection('PrecioSuscripciones').onSnapshot((querySnapshot) => {
			const documents = [];
			querySnapshot.forEach((doc) => {
				documents.push({...doc.data(), id: doc.id});
			});
			setDocs(documents);
		});
	}

	function agregarPrecioSuscripciones() {
		const data = {
			nombre: nombre,
			precio: 0,
			duracion: 0
		}
		db.collection('PrecioSuscripciones').add(data)
	}

	function openModal(item) {
		setSelected(item);
		setShow(true);
	}

	function closeModal() {
		setSelected({});
		setShow(false);
	}

	return (
		<div className="preciosuscripciones page">
			<div className="row">
				<input className="input" value={nombre} onChange={(event) => setNombre(event.target.value)}/>
				<button className="btn" onClick={() => agregarPrecioSuscripciones()}>Agregar</button>
			</div>
			<div className="grid-container">
				<div className="grid-row-header">
					<h1>Duración</h1>
					<h1>Nombre</h1>
					<h1>Precio</h1>
					<div className="grid-action">
					</div>
				</div>
				{docs.map(doc => <div className="grid-row" key={doc.id}>
					<h1>{doc.duracion}</h1>
					<h1>{doc.nombre}</h1>
					<h1>$ {doc.precio}</h1>
					<div className="grid-action" onClick={() => openModal(doc)}>
						<i className="fas fa-edit"></i>
					</div>
				</div>)}
			</div>
			<Modal
				show={show}
				onClose={() => closeModal()}
				title={selected.nombre}>
				<Editar
					id={selected.id}
					duracion={selected.duracion}
					nombre={selected.nombre}
					precio={selected.precio}
					closeModal={() => closeModal()}
				/>
			</Modal>
		</div>
	)
}

const Editar = (props) => {
	const [info, setInfo] = useState({id:"", nombre: "", precio: 0, duracion: 0});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setInfo(props)
	}, [props])

	async function onChange(item, value) {
		setInfo({...info, [item]: value})
	}

	async function onSubmit(event) {
		event.preventDefault();
		setLoading(true);
		await db.collection('PrecioSuscripciones')
			.doc(info.id)
			.update({nombre: info.nombre, duracion: info.duracion, precio: info.precio})
			.then(() =>{
				props.closeModal();
				Swal.fire("Actualizado", "", "success");
			}).catch(err => {
				console.log(err);
				Swal.fire("Oops!", "Ocurrio un error al actualizar", "error");
			});
		setLoading(false);
	}

	return (
		<div className="column full justify-center align-center">
			<form className="column align-center" onSubmit={(event) => onSubmit(event)}>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Duración: <input className="input" type="number" name="nombre" value={info.duracion || 0} onChange={(event) => onChange('duracion', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Nombre: <input className="input" type="text" name="imagen" value={info.nombre || ""} onChange={(event) => onChange('nombre', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Precio: <input className="input" type="number" name="link" value={info.precio || 0} onChange={(event) => onChange('precio', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>

				{loading ? 
					<div>
						<i className="fas fa-spinner fa-spin"></i>
					</div>
					:
					<button className="btn">Actualizar</button>
				}

				<div className="white-space-16"></div>
			</form>
		</div>
	)
}

export default PrecioSuscripciones;

import React, { useState } from 'react';
import { db, auth } from '../firebase';
import Swal from 'sweetalert2';

const Login = () => {

	useState(() => {
	}, []);

	async function iniciarSesion(event) {
		event.preventDefault();
		const form = event.target;
		console.log(form.password.value);
		auth.signInWithEmailAndPassword(form.usuario.value, form.password.value).then((userCredential) => {
			Swal.fire('', '', 'success');
		}).catch(err => {
			// console.log(err);
			Swal.fire('', 'Usuario o contraseña no válidos', 'error');
		})
	}

	function logOut() {
		auth.signOut();
	}

	auth.onAuthStateChanged(async (user) => {
		if (user) {
			console.log(user.email);
			const doc = await db.collection('Usuarios').where("email", "==", user.email).get();
			const { rol } = doc.docs[0].data();
			if (rol !== "Administrador") {
				logOut();
			} else {
				localStorage.setItem("usermail", user.email);
				window.location.reload();
			}
		}
	});

	return (
		<div className="login page justify-center align-center">
			<form onSubmit={(event) => iniciarSesion(event)} className="column align-center justify-center">
				<img src="DrEnarm_tp.png" alt="logo"/>
				<input className="input" name="usuario" required type="email"/>
				<div className="white-space-8"></div>
				<input className="input" name="password" required type="password"/>
				<div className="white-space-8"></div>
				<button className="btn" type="submit">Iniciar</button>
			</form>
		</div>
	)
}

export default Login;

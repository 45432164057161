import React from 'react';

const Home = () => {
	return (
		<div className="home page">
			<h1>Bienvenido</h1>
		</div>
	)
}

export default Home;

import React, { useEffect, useState } from 'react';

const Modal = (props) => {
	const [open, setOpen] = useState(props.show);

	useEffect(() => {
		setOpen(props.show);
	}, [props.show]);

	function closeModal() {
		setOpen(false);
		if(props.onClose) {
			props.onClose();
		}
	}

	return (
		<div className={"modal " + ( open ? "" : "hidden" )} id={props.idModal || 'showModal'}>
			<div className="modal-content">
				<div className="modal-header">
					<h1>{props.title}</h1>
					<div className="close" onClick={() => closeModal()}>
						<i className="fas fa-times"></i>
					</div>
				</div>
				<div className="modal-body">
					{props.children}
				</div>
			</div>
		</div>
	)
}

export default Modal;
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { db } from '../firebase';
import { uploadFile } from '../cloudinary';

import Modal from '../components/Modal';
import DropImage from '../components/DropImage';

const Categorias = () => {
	const [nombre, setNombre] = useState("");
	const [docs, setDocs] = useState([]);
	const [show, setShow] = useState(false);
	const [selected, setSelected] = useState({});

	useState(() => {
		getCategorias();
	}, []);

	function getCategorias() {
		db.collection('Categorias').orderBy("nombre", "asc").onSnapshot((querySnapshot) => {
			let documents = [];
			querySnapshot.forEach((doc) => {
				documents.push({...doc.data(), id: doc.id});
			});
			setDocs(documents);
		});
	}

	function agregarCategorias() {
		const data = {
			nombre: nombre,
			imagen: ""
		}
		db.collection('Categorias').add(data)
	}

	function openModal(item) {
		setSelected(item);
		setShow(true);
	}

	function closeModal() {
		setSelected({});
		setShow(false);
	}

	return (
		<div className="categorias page">
			<div className="row">
				<input className="input" value={nombre} onChange={(event) => setNombre(event.target.value)}/>
				<button className="btn" onClick={() => agregarCategorias()}>Agregar</button>
			</div>
			<div className="card-container">
				{docs.map(doc => <div className="card" key={doc.id}>
					<div className="image-content" onClick={() => openModal(doc)}>
						<img src={doc.imagen || "noImage.jpeg"} alt={doc.nombre}/>
					</div>
					<div className="title">
						<h1>{doc.nombre}</h1>
					</div>
				</div>)}
			</div>
			<Modal
				show={show}
				onClose={() => closeModal()}
				title={selected.nombre}>
				<CambiarImagen
					id={selected.id}
					imagen={selected.imagen}
					nombre={selected.nombre}
					closeModal={() => closeModal()}
				/>
			</Modal>
		</div>
	)
}

const CambiarImagen = (props) => {
	const [info, setInfo] = useState(props);
	const [loading, setLoading] = useState(false);
	const [imagen, setImagen] = useState(props.imagen || "noImage.jpeg");

	useEffect(() => {
		setInfo(props);
		setImagen(props.imagen || "noImage.jpeg");
	}, [props])

	async function onChange(item, value) {
		setInfo({...info, [item]: value})
	}

	async function onSubmit(event) {
		event.preventDefault();
		setLoading(true);
		await db.collection('Categorias')
			.doc(info.id)
			.update({nombre: info.nombre, imagen: imagen})
			.then(() =>{
				props.closeModal();
				Swal.fire("Actualizado", "", "success");
			}).catch(err => {
				console.log(err);
				Swal.fire("Oops!", "Ocurrio un error al actualizar", "error");
			});
		setLoading(false);
	}

	async function uploadImage(data) {
		//Upload Cloudinary
		setLoading(true);

		const resultUpload = await uploadFile(data.file);

		if(resultUpload.secure_url || resultUpload.url) {
			setImagen(resultUpload.secure_url || resultUpload.url);
		}

		setLoading(false);
	}

	return (
		<div className="column full justify-center align-center categoria_modal">
			<DropImage
				setData={(data) => uploadImage(data)}
				setLoading={(load) => setLoading(load)}>
				<img src={`${imagen || 'noImage.jpeg'}`} alt={props.nombre || "Categoria"}/>
			</DropImage>
			<form className="column align-center" onSubmit={(event) => onSubmit(event)}>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Nombre: <input className="input" name="nombre" value={info.nombre || ""} onChange={(event) => onChange('nombre', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>

				{loading ? 
					<div>
						<i className="fas fa-spinner fa-spin"></i>
					</div>
					:
					<button className="btn">Actualizar</button>
				}

				<div className="white-space-16"></div>
			</form>
		</div>
	)
}

export default Categorias;
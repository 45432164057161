import React from "react";
import { NavLink } from "react-router-dom";

const menus = [
	{name: "Inicio", path:"/home"},
	{name: "Anuncios", path:"/anuncios"},
	{name: "Categorias", path:"/categorias"},
	{name: "PdfDisponibles", path:"/pdfdisponibles"},
	{name: "PrecioSuscripciones", path:"/preciosuscripciones"},
	{name: "Suscripciones", path:"/suscripciones"},
	{name: "Usuarios", path:"/usuarios"},
	{name: "Preguntas", path:"/preguntas"}
]

const Sidebar = (props) => {

	function opensidebar() {
        const bar = document.getElementById("sidebar-mobile");
		if(bar.classList.contains('open')) {
			bar.classList.remove('open');
		}else {
            bar.classList.add('open');
        }
    }

	return (
		<div className="row full view dashboard">
			<div className="btn-column btn-menu column">
				<i className="fas fa-bars color-white btn-sidebar " onClick={opensidebar.bind(this)}></i>
			</div>
			<div className="side-bar">
				{menus.map(menu =>
					<div key={menu.name} className="row nav-item full justify-start align-center">
						<NavLink
							to={menu.path}
							className="font-regular color-white align-center">
								<span className="color-white weight-light">{menu.name}</span>
						</NavLink>
					</div>
				)}
			</div>
			<div className="column full align-center children-view">
				{props.children}
			</div>
		</div>
	)
}

export default Sidebar;

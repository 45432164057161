import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

import { db } from '../../firebase';

const BuscarPreguntas = (props) => {
	const [docs, setDocs] = useState([]);
	const [edit, setEdit] = useState('');
	const [categorias, setCategorias] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setEdit('');
		getCategorias();
	}, []);

	useEffect(()=> {
		getPreguntas();
	}, [categorias]);

	function reload() {
		getPreguntas();
	}

	async function getCategorias() {
		await db.collection('Categorias').orderBy("nombre", "asc").onSnapshot((querySnapshot) => {
			let cats = [];
			querySnapshot.forEach((doc) => {
				cats.push({...doc.data(), id: doc.id});
			});
			setCategorias(cats);
		});
	}

	async function getPreguntas() {
		setLoading(true);
		const { id, categoria, pregunta } = props.match.params;
		if (categoria && id) {
			if(categoria === 'all' && categorias.length > 0) {
				let documents = [];
				for (let index in categorias) {
					const cat = categorias[index];
					console.log(cat.nombre);
					let querySnapshot = await db.collection('preguntas').doc(id).collection(cat.nombre).get();
					querySnapshot.forEach(doc => {
						if(doc.data().name.toLowerCase().indexOf((pregunta || '').toLowerCase()) !== -1)
							documents.push({...doc.data(), id: doc.id, categoria: cat.nombre});
					});
				};
				setDocs(documents);
			} else {
				await db.collection('preguntas').doc(id).collection(categoria).onSnapshot(querySnapshot => {
					let documents = [];
					querySnapshot.forEach(doc => {
						if(doc.data().name.toLowerCase().indexOf((pregunta || '').toLowerCase()) !== -1)
							documents.push({...doc.data(), id: doc.id, categoria});
					});
					setDocs(documents);
				});
			}
		}
		setLoading(false);
	}

	if(loading) {
		return (
			<div className="preguntas page">
				<div className="column justify-center align-center full">
					<h1>Obtieniendo preguntas...</h1>
				</div>
			</div>
		)
	}

	if(docs.length <= 0) {
		return (
			<div className="preguntas page">
				<div className="column justify-center align-center full">
					<h1>Sin resultados</h1>
				</div>
			</div>
		)
	}

	return (
		<div className="preguntas page">
			<div className="column full">
				{docs.map(doc => edit === doc.id? 
					<ItemEdit
						docId={props.match.params.id}
						categoria={doc.categoria}
						doc={doc}
						setEdit={setEdit}
						reload={() => reload()}/>
					:
					<Item
						doc={doc}
						key={doc.id}
						setEdit={setEdit}/>)}
			</div>
		</div>
	)
}

const Item = ({doc, setEdit}) => {
	return (
		<div key={doc.id} className="pregunta">
			<div className="edit-buttons">
				<button className="btn" onClick={() => setEdit(doc.id)}>
					<i className="fas fa-edit"></i>
				</button>
			</div>
			<div className="row justify-center">
				<label className="categoria">{doc.categoria}</label>
			</div>
			<div className="nombre">
				<div className="title">
					Pregunta:
				</div>
				{doc.name}
			</div>
			<div className="white-space-8"></div>
			<div className="justificacion">
				<div className="title">
					Justificación:
				</div>
				{doc.justificacion}
			</div>
			<div className="white-space-8"></div>
			<div className="respuestas">
				<div className="title">
					Respuestas:
				</div>
				<ul>
					<li className={doc.respuestaTest === doc.respuestaUno && 'correcta'}>1.{doc.respuestaUno}</li>
					<li className={doc.respuestaTest === doc.respuestaDos && 'correcta'}>2.{doc.respuestaDos}</li>
					<li className={doc.respuestaTest === doc.respuestaTres && 'correcta'}>3.{doc.respuestaTres}</li>
					<li className={doc.respuestaTest === doc.respuestaCuatro && 'correcta'}>4.{doc.respuestaCuatro}</li>
				</ul>
			</div>
		</div>
	)
}

const ItemEdit = ({doc, docId, setEdit, categoria, reload}) => {
	const [info, setInfo] = useState(doc);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setInfo(doc);
	}, [doc]);

	async function onChange(item, value) {
		setInfo({...info, [item]: value});
		if(('respuestaUno,respuestaDos,respuestaTres,respuestaCuatro').indexOf(item) !== -1) {
			setInfo({...info, [item]: value, respuestaTest: ''});
		}
	}

	async function updatePregunta() {
		if(!info.respuestaTest) {
			Swal.fire("Oops!", "Se debe seleccionar una respuesta correcta", "warning");
			return;
		}

		setLoading(true);

		await db.collection('preguntas')
			.doc(docId)
			.collection(categoria)
			.doc(info.id)
			.update({
				name: info.name,
				fotoPregunta: info.fotoPregunta || 'NOIMAGE',
				justificacion: info.justificacion,
				respuestaUno: info.respuestaUno,
				respuestaDos: info.respuestaDos,
				respuestaTres: info.respuestaTres,
				respuestaCuatro: info.respuestaCuatro,
				respuestaTest: info.respuestaTest,
				uid: info.uid || ''
			})
			.then(() => {
				setEdit('')
				Swal.fire("Actualizado", "", "success");
				reload()
			}).catch(err => {
				console.log(err);
				Swal.fire("Oops!", "Ocurrio un error al actualizar", "error");
			});

		setLoading(false);
	}

	if(loading) {
		return (
			<div className="pregunta">
				<div className="column justify-center align-center">
					<i className="fas fa-spinner fa-spin"/>
				</div>
			</div>
		)
	}

	return (
		<div className="pregunta">
			<div className="edit-buttons">
				<button className="btn" onClick={() => updatePregunta()}>
					<i className="fas fa-save"></i>
				</button>
				<button className="btn" onClick={() => setEdit('')}>
					<i className="fas fa-times"></i>
				</button>
			</div>
			<div className="row justify-center">
				<label className="categoria">{info.categoria}</label>
			</div>
			<div className="nombre">
				<div className="title">
					Pregunta:
				</div>
				<input className="input" name="name" value={info.name || ""} onChange={(event) => onChange('name', event.target.value)}></input>
			</div>
			<div className="white-space-8"></div>
			<div className="justificacion">
				<div className="title">
					Justificación:
				</div>
				<input className="input" name="justificacion" value={info.justificacion || ""} onChange={(event) => onChange('justificacion', event.target.value)}></input>
			</div>
			<div className="white-space-8"></div>
			<div className="respuestas">
				<div className="title">
					Respuestas:
				</div>
				<ul>
					<li>1.
						<input className="input" name="respuestaUno" value={info.respuestaUno || ""} onChange={(event) => onChange('respuestaUno', event.target.value)}></input>
						<input
							type="radio"
							name={`${info.id}`}
							value={info.respuestaUno}
							checked={info.respuestaTest === info.respuestaUno? true: false}
							onChange={(r) => {
								if(r.target.checked) {
									onChange('respuestaTest', r.target.value);
								}
							}}/>
					</li>
					<li>2.
						<input className="input" name="respuestaDos" value={info.respuestaDos || ""} onChange={(event) => onChange('respuestaDos', event.target.value)}></input>
						<input
							type="radio"
							name={`${info.id}`}
							value={info.respuestaDos}
							checked={info.respuestaTest === info.respuestaDos? true: false}
							onChange={(r) => {
								if(r.target.checked) {
									onChange('respuestaTest', r.target.value);
								}
							}}/>
					</li>
					<li>3.
						<input className="input" name="respuestaTres" value={info.respuestaTres || ""} onChange={(event) => onChange('respuestaTres', event.target.value)}></input>
						<input
							type="radio"
							name={`${info.id}`}
							value={info.respuestaTres}
							checked={info.respuestaTest === info.respuestaTres? true: false}
							onChange={(r) => {
								if(r.target.checked) {
									onChange('respuestaTest', r.target.value);
								}
							}}/>
					</li>
					<li>4.
						<input className="input" name="respuestaCuatro" value={info.respuestaCuatro || ""} onChange={(event) => onChange('respuestaCuatro', event.target.value)}></input>
						<input
							type="radio"
							name={`${info.id}`}
							value={info.respuestaCuatro}
							checked={info.respuestaTest === info.respuestaCuatro? true: false}
							onChange={(r) => {
								if(r.target.checked) {
									onChange('respuestaTest', r.target.value);
								}
							}}/>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default BuscarPreguntas;

import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { db } from '../firebase';

import Modal from '../components/Modal';

const Suscripciones = () => {
	// const [nombre, setNombre] = useState("");
	const [docs, setDocs] = useState([]);
	const [show, setShow] = useState(false);
	const [selected, setSelected] = useState({});

	useState(() => {
		getSuscripciones();
	}, []);

	function getSuscripciones() {
		db.collection('Suscripciones').onSnapshot((querySnapshot) => {
			const documents = [];
			querySnapshot.forEach((doc) => {
				documents.push({...doc.data(), id: doc.id});
			});
			setDocs(documents);
		});
	}

	function openModal(item) {
		setSelected(item);
		setShow(true);
	}

	function closeModal() {
		setSelected({});
		setShow(false);
	}

	return (
		<div className="suscripciones page">
			<div className="grid-container">
				<div className="grid-row-header">
					<h1>Email</h1>
					<h1>Inicio</h1>
					<h1>Fin</h1>
					<div className="grid-action">
					</div>
				</div>
				{docs.map(doc => <div className="grid-row" key={doc.id}>
					<h1>{doc.email}</h1>
					<h1>{doc.inicio}</h1>
					<h1>{doc.fin}</h1>
					<div className="grid-action" onClick={() => openModal(doc)}>
						<i className="fas fa-edit"></i>
					</div>
				</div>)}
			</div>
			<Modal
				show={show}
				onClose={() => closeModal()}
				title={selected.email}>
				<Editar
					id={selected.id}
					fin={selected.fin}
					inicio={selected.inicio}
					email={selected.email}
					monto={selected.monto}
					periodicidad={selected.periodicidad}
					closeModal={() => closeModal()}
				/>
			</Modal>
		</div>
	)
}

const Editar = (props) => {
	const [info, setInfo] = useState({ id:"", fin: "", inicio: "", monto: 0, periodicidad: "" });
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setInfo(props)
	}, [props])

	async function onChange(item, value) {
		setInfo({...info, [item]: value})
	}

	async function onSubmit(event) {
		event.preventDefault();
		setLoading(true);
		await db.collection('Suscripciones')
			.doc(info.id)
			.update({fin: info.fin, inicio: info.inicio, email: info.email, monto: info.monto, periodicidad: info.periodicidad})
			.then(() =>{
				props.closeModal();
				Swal.fire("Actualizado", "", "success");
			}).catch(err => {
				console.log(err);
				Swal.fire("Oops!", "Ocurrio un error al actualizar", "error");
			});
		setLoading(false);
	}

	return (
		<div className="column full justify-center align-center">
			<form className="column align-center" onSubmit={(event) => onSubmit(event)}>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Fin: <input className="input" type="date" name="fin" value={info.fin || 0} onChange={(event) => onChange('fin', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Inicio: <input className="input" type="date" name="inicio" value={info.inicio || ""} onChange={(event) => onChange('inicio', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Email: <input className="input" type="email" name="link" value={info.email || ""} onChange={(event) => onChange('email', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Monto: <input className="input" type="number" name="link" value={info.monto || 0} onChange={(event) => onChange('monto', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Periodicidad: <input className="input" type="text" name="periodicidad" value={info.periodicidad || ""} onChange={(event) => onChange('periodicidad', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>

				{loading ? 
					<div>
						<i className="fas fa-spinner fa-spin"></i>
					</div>
					:
					<button className="btn">Actualizar</button>
				}

				<div className="white-space-16"></div>
			</form>
		</div>
	)
}

export default Suscripciones;

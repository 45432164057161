import React, { useState } from 'react';
import Swal from 'sweetalert2';

import { db } from '../../firebase';

import DropFile from '../../components/DropFIle';

const Importar = (props) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	async function cargarPreguntas() {
		setLoading(true);
		const { id, categoria } = props.params;
		let batch = db.batch();

		data.forEach((doc) => {
			batch.set(db.collection('preguntas').doc(id).collection(categoria).doc(), doc);
		})
		// Commit the batch
		batch.commit().then(function () {
			setLoading(false);
			Swal.fire("", "Datos importados correctamente", "success");
			setData([]);
			props.cancel();
		}).catch(err => {
			setLoading(false);
			Swal.fire("Oops!", "Algo salio mal", "error");
		});
	}

	function downloadPlantilla() {
		const a = document.createElement("a");
		a.href = "/resources/preguntas.csv";
		a.setAttribute('download', "preguntas.csv");
		a.target = "_newtab";
		a.click();
		a.remove()
	}

	return (
		<div className="preguntas page">
			<div className="column justify-center align-center">
				<div className="row full justify-between">
					<button className="btn" onClick={() => downloadPlantilla()}>Descargar Plantilla <i className="fas fa-download"></i></button>
					<button className="btn" onClick={() => props.cancel()}>Cancelar</button>
				</div>
				<DropFile setData={(data) => setData(data)}/>
			</div>
			<div className="white-space-8"></div>
			<div className="table-responsive">
				<table>
					<thead>
						<tr>
							<th>Nombre</th>
							<th>Justificación</th>
							<th>respuestaUno</th>
							<th>respuestaDos</th>
							<th>respuestaTres</th>
							<th>respuestaCuatro</th>
						</tr>
					</thead>
					<tbody>
						{data.map((row, index) =>
							<tr key={index}>
								<td>{row.name}</td>
								<td>{row.justificacion}</td>
								<td>{row.respuestaUno}</td>
								<td>{row.respuestaDos}</td>
								<td>{row.respuestaTres}</td>
								<td>{row.respuestaCuatro}</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<div className="white-space-32"></div>
			{loading ? 
				<div>
					<i className="fas fa-spinner fa-spin"></i>
				</div>
				:
				<button className="btn" onClick={() => cargarPreguntas()}>Cargar Información</button>
			}
		</div>
	)
}

export default Importar;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { db } from '../firebase';

const Preguntas = () => {
	const [docId, setdocId] = useState("");
	const [docs, setDocs] = useState([]);
	const [search, setSearch] = useState({
		categoria: "",
		pregunta: ""
	})

	const history = useHistory();

	useState(() => {
		getCategorias();
	}, []);

	function getCategorias() {
		db.collection('Categorias').orderBy("nombre", "asc").onSnapshot((querySnapshot) => {
			let documents = [];
			querySnapshot.forEach((doc) => {
				documents.push({...doc.data(), id: doc.id});

				getPreguntas(doc.data().nombre);
			});
			setDocs(documents);
		});
	}

	function getPreguntas() {
		db.collection('preguntas').get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				setdocId(doc.id);
			});
		});
	}

	function abrirPreguntas(id, categoria) {
		history.push(`/preguntas/${docId}/${categoria}`);
	}

	function buscar(event) {
		event.preventDefault();
		history.push(`/preguntas/${docId}/${search.categoria}/${search.pregunta}`);
	}

	return (
		<div className="preguntas page">
			<div className="column full">
				<form className="row full" onSubmit={(event) => buscar(event)}>
					<select
						required
						className="input input-small"
						value={search.categoria}
						onChange={(event) => setSearch({...search, categoria: event.target.value})}>
						<option value="">Elige Categoria</option>
						<option value="all">Todas</option>
						{docs.map(doc => <option key={doc.id} value={doc.nombre}>{doc.nombre}</option>)}
					</select>
					<input
						required
						className="input"
						placeholder="Escribe alguna pregunta para buscarla"
						value={search.pregunta}
						onChange={(event) => setSearch({...search, pregunta: event.target.value})}/>
					<button className="btn"><i className="fas fa-search"></i></button>
				</form>
			</div>
			<div className="card-container">
				{docs.map(doc => <div className="card" key={doc.id} onClick={() => abrirPreguntas(doc.id, doc.nombre)}>
					<div className="image-content">
						<img src={doc.imagen || "https://khn.org/wp-content/uploads/sites/2/2018/04/surgery.jpg?w=1024"} alt={doc.nombre}/>
					</div>
					<div className="title">
						<h1>{doc.nombre}</h1>
					</div>
				</div>)}
			</div>
		</div>
	)
}

export default Preguntas;

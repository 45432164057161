import React from 'react';
import { auth } from '../firebase';
// import Swal from 'sweetalert2';

const Header = () => {
	function logOut() {
		auth.signOut();
	}

	auth.onAuthStateChanged(async (user) => {
		if (!user) {
			localStorage.removeItem("usermail");
			window.location.reload();
		}
	});

	return (
		<header>
			<h1>DRERNAM</h1>
			<button onClick={() => logOut()} className="btn" type="submit">Salir</button>
		</header>
	)
}

export default Header;

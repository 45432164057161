import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

import { db } from '../../firebase';
import { uploadFile } from '../../cloudinary';

import Modal from '../../components/Modal';
import DropImage from '../../components/DropImage';

import Importar from './Importar';

const ListaPreguntas = (props) => {
	const [docs, setDocs] = useState([]);
	const [show, setShow] = useState(false);
	const [edit, setEdit] = useState('');
	const [importar, setImportar] = useState(false);

	useState(() => {
		getPreguntas();
	}, []);

	function getPreguntas() {
		const { id, categoria } = props.match.params;
		if (categoria && id) {
			db.collection('preguntas').doc(id).collection(categoria).onSnapshot(querySnapshot => {
				let documents = [];
				querySnapshot.forEach(doc => {
					documents.push({...doc.data(), id: doc.id});
				});
				setDocs(documents);
			});
		}
	}

	function agregarPregunta() {
		openModal()
	}

	function importarPregunta() {
		setImportar(true);
	}

	function openModal() {
		setShow(true);
	}

	function closeModal() {
		setShow(false);
	}

	function exportarPreguntas() {
		window.print()
		/*const { categoria } = props.match.params;
		const json = docs;
		const fields = ['fotoPregunta', 'name', 'justificacion', 'respuestaUno', 'respuestaDos', 'respuestaTres', 'respuestaCuatro'];
		const replacer = function(key, value) { return value === null ? '' : value } 
		let csv = json.map(function(row){
			return fields.map(function(fieldName){
				return JSON.stringify(row[fieldName] || "", replacer)
			}).join(',')
		})
		csv.unshift(fields.join(',')) // add header column
		csv = csv.join('\r\n');

		const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
		let encodedUri = URL.createObjectURL(blob);
		let link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `${categoria}.csv`);
		document.body.appendChild(link); // Required for FF
		link.click();*/
	}

	if (importar) {
		return (
			<Importar params={props.match.params} cancel={() => setImportar(false)}/>
		)
	}

	return (
		<div className="preguntas page">
			<div className="row justify-end">
				<button className="btn" onClick={() => agregarPregunta()}>Agregar</button>
				<button className="btn" onClick={() => exportarPreguntas()}>Exportar</button>
				<button className="btn" onClick={() => importarPregunta()}>Importar</button>
			</div>
			<div className="column full" id="lista-preguntas">
				{docs.map(doc => edit === doc.id? 
					<ItemEdit
						docId={props.match.params.id}
						categoria={props.match.params.categoria}
						doc={doc}
						setEdit={setEdit}/>
					:
					<Item
						doc={doc}
						key={doc.id}
						setEdit={setEdit}/>)}
			</div>
			<Modal
				idModal="agregarPregunta"
				show={show}
				onClose={() => closeModal()}
				title="Agregar Pregunta">
				<AgregarPregunta
					closeModal={() => closeModal()}
					docId={props.match.params.id}
					categoria={props.match.params.categoria}
				/>
			</Modal>
		</div>
	)
}

const Item = ({doc, setEdit}) => {
	return (
		<div key={doc.id} className="pregunta" style={{backgroundImage: `url(${(doc.fotoPregunta && doc.fotoPregunta !== 'NOIMAGE') ? doc.fotoPregunta : '/noImage.jpeg'})`}}>
			<div className="edit-buttons">
				<button className="btn" onClick={() => setEdit(doc.id)}>
					<i className="fas fa-edit"></i>
				</button>
			</div>
			<div className="nombre">
				<div className="title">
					Pregunta:
				</div>
				{doc.name}
			</div>
			<div className="white-space-8"></div>
			<div className="justificacion">
				<div className="title">
					Justificación:
				</div>
				{doc.justificacion}
			</div>
			<div className="white-space-8"></div>
			<div className="respuestas">
				<div className="title">
					Respuestas:
				</div>
				<ul>
					<li className={doc.respuestaTest === doc.respuestaUno && 'correcta'}>1.{doc.respuestaUno}</li>
					<li className={doc.respuestaTest === doc.respuestaDos && 'correcta'}>2.{doc.respuestaDos}</li>
					<li className={doc.respuestaTest === doc.respuestaTres && 'correcta'}>3.{doc.respuestaTres}</li>
					<li className={doc.respuestaTest === doc.respuestaCuatro && 'correcta'}>4.{doc.respuestaCuatro}</li>
				</ul>
			</div>
			<div style={{pageBreakBefore: "always"}}> </div>
		</div>
	)
}

const ItemEdit = ({doc, docId, setEdit, categoria}) => {
	const [info, setInfo] = useState(doc);
	const [imagen, setImagen] = useState(doc.fotoPregunta && doc.fotoPregunta !== 'NOIMAGE'? doc.fotoPregunta : '/noImage.jpeg');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setInfo(doc);
	}, [doc]);

	async function onChange(item, value) {
		setInfo({...info, [item]: value});
		if(('respuestaUno,respuestaDos,respuestaTres,respuestaCuatro').indexOf(item) !== -1) {
			setInfo({...info, [item]: value, respuestaTest: ''});
		}
	}

	async function updatePregunta() {
		if(!info.respuestaTest) {
			Swal.fire("Oops!", "Se debe seleccionar una respuesta correcta", "warning");
			return;
		}

		setLoading(true);

		await db.collection('preguntas')
			.doc(docId)
			.collection(categoria)
			.doc(info.id)
			.update({
				name: info.name,
				fotoPregunta: imagen || info.fotoPregunta || 'NOIMAGE',
				justificacion: info.justificacion,
				respuestaUno: info.respuestaUno,
				respuestaDos: info.respuestaDos,
				respuestaTres: info.respuestaTres,
				respuestaCuatro: info.respuestaCuatro,
				respuestaTest: info.respuestaTest,
				uid: info.uid || ''
			})
			.then(() => {
				setEdit('')
				Swal.fire("Actualizado", "", "success");
			}).catch(err => {
				console.log(err);
				Swal.fire("Oops!", "Ocurrio un error al actualizar", "error");
			});

		setLoading(false);
	}

	async function uploadImagen(data) {
		//Upload Cloudinary
		setLoading(true);

		const resultUpload = await uploadFile(data.file);

		if(resultUpload.secure_url || resultUpload.url) {
			setImagen(resultUpload.secure_url || resultUpload.url);
		}

		setLoading(false);
	}

	if(loading) {
		return (
			<div className="pregunta">
				<div className="column justify-center align-center">
					<i className="fas fa-spinner fa-spin"/>
				</div>
			</div>
		)
	}

	return (
		<div className="pregunta">
			<div className="edit-buttons">
				<button className="btn" onClick={() => updatePregunta()}>
					<i className="fas fa-save"></i>
				</button>
				<button className="btn" onClick={() => setEdit('')}>
					<i className="fas fa-times"></i>
				</button>
			</div>
			<div className="row full">
				<div className="column full">
					<div className="nombre">
						<div className="title">
							Pregunta:
						</div>
						<input className="input" name="name" value={info.name || ""} onChange={(event) => onChange('name', event.target.value)}></input>
					</div>
					<div className="white-space-8"></div>
					<div className="justificacion">
						<div className="title">
							Justificación:
						</div>
						<input className="input" name="justificacion" value={info.justificacion || ""} onChange={(event) => onChange('justificacion', event.target.value)}></input>
					</div>
					<div className="white-space-8"></div>
					<div className="respuestas">
						<div className="title">
							Respuestas:
						</div>
						<ul>
							<li>1.
								<input className="input" name="respuestaUno" value={info.respuestaUno || ""} onChange={(event) => onChange('respuestaUno', event.target.value)}></input>
								<input
									type="radio"
									name={`${info.id}`}
									value={info.respuestaUno}
									checked={info.respuestaTest === info.respuestaUno? true: false}
									onChange={(r) => {
										if(r.target.checked) {
											onChange('respuestaTest', r.target.value);
										}
									}}/>
							</li>
							<li>2.
								<input className="input" name="respuestaDos" value={info.respuestaDos || ""} onChange={(event) => onChange('respuestaDos', event.target.value)}></input>
								<input
									type="radio"
									name={`${info.id}`}
									value={info.respuestaDos}
									checked={info.respuestaTest === info.respuestaDos? true: false}
									onChange={(r) => {
										if(r.target.checked) {
											onChange('respuestaTest', r.target.value);
										}
									}}/>
							</li>
							<li>3.
								<input className="input" name="respuestaTres" value={info.respuestaTres || ""} onChange={(event) => onChange('respuestaTres', event.target.value)}></input>
								<input
									type="radio"
									name={`${info.id}`}
									value={info.respuestaTres}
									checked={info.respuestaTest === info.respuestaTres? true: false}
									onChange={(r) => {
										if(r.target.checked) {
											onChange('respuestaTest', r.target.value);
										}
									}}/>
							</li>
							<li>4.
								<input className="input" name="respuestaCuatro" value={info.respuestaCuatro || ""} onChange={(event) => onChange('respuestaCuatro', event.target.value)}></input>
								<input
									type="radio"
									name={`${info.id}`}
									value={info.respuestaCuatro}
									checked={info.respuestaTest === info.respuestaCuatro? true: false}
									onChange={(r) => {
										if(r.target.checked) {
											onChange('respuestaTest', r.target.value);
										}
									}}/>
							</li>
						</ul>
					</div>
				</div>
				<div className="column image-pregunta-upload full">
					<DropImage setData={uploadImagen}>
						<img src={imagen || 'noImage.jpeg'} alt={doc.id}/>
					</DropImage>
				</div>
			</div>
		</div>
	)
}

const AgregarPregunta = (props) => {
	const [info, setInfo] = useState({
		fotoPregunta: "",
		name: "",
		justificacion: "",
		respuestaUno: "",
		respuestaDos: "",
		respuestaTres: "",
		respuestaCuatro: "",
		respuestaTest: "",
		uid: ""
	});
	const [loading, setLoading] = useState(false);

	async function onChange(item, value) {
		setInfo({...info, [item]: value})
	}

	async function onSubmit(event) {
		event.preventDefault();

		if(!info.respuestaTest) {
			Swal.fire("Oops!", "Se debe seleccionar una respuesta correcta", "warning");
			return;
		}

		setLoading(true);

		await db.collection('preguntas')
			.doc(props.docId)
			.collection(props.categoria)
			.add({
				name: info.name,
				fotoPregunta: info.fotoPregunta || 'NOIMAGE',
				justificacion: info.justificacion,
				respuestaUno: info.respuestaUno,
				respuestaDos: info.respuestaDos,
				respuestaTres: info.respuestaTres,
				respuestaCuatro: info.respuestaCuatro,
				respuestaTest: info.respuestaTest,
				uid: info.uid
			})
			.then(() =>{
				props.closeModal();
				Swal.fire("Agregado", "", "success");
			}).catch(err => {
				console.log(err);
				Swal.fire("Oops!", "Ocurrio un error al agregar", "error");
			});

		setLoading(false);
	}

	return (
		<div className="column full justify-center align-center">
			<form className="column align-center" onSubmit={(event) => onSubmit(event)}>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Imagen(URL): <input className="input" name="imagen" value={info.fotoPregunta || ""} onChange={(event) => onChange('fotoPregunta', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Nombre: <input required className="input" name="nombre" value={info.name || ""} onChange={(event) => onChange('name', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Justificación: <input required className="input" name="nombre" value={info.justificacion || ""} onChange={(event) => onChange('justificacion', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					respuestaUno: <input required className="input" name="respuestaUno" value={info.respuestaUno || ""} onChange={(event) => onChange('respuestaUno', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					respuestaDos: <input required className="input" name="respuestaDos" value={info.respuestaDos || ""} onChange={(event) => onChange('respuestaDos', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					respuestaTres: <input required className="input" name="respuestaTres" value={info.respuestaTres || ""} onChange={(event) => onChange('respuestaTres', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					respuestaCuatro: <input required className="input" name="respuestaCuatro" value={info.respuestaCuatro || ""} onChange={(event) => onChange('respuestaCuatro', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					respuesta correcta: <input required className="input" name="respuestaTest" value={info.respuestaTest || ""} onChange={(event) => onChange('respuestaTest', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				{loading ? 
					<div>
						<i className="fas fa-spinner fa-spin"></i>
					</div>
					:
					<button className="btn">Agregar</button>
				}

				<div className="white-space-16"></div>
			</form>
		</div>
	)
}

export default ListaPreguntas;

import React, { useEffect } from 'react';

const DropFile = (props) => {

	useEffect(() => {
		const fileDrag		= document.getElementById('file-drag');
		const fileSelect	= document.getElementById('file-upload');
		const fileText		= document.getElementById('file-text');

		if (fileDrag) {
			fileDrag.addEventListener('dragover', fileDragHover, false);
			fileDrag.addEventListener('dragleave', fileDragHover, false);
			fileDrag.addEventListener('drop', fileSelectHandler, false);
		}
	
		if (fileSelect) {
			fileSelect.addEventListener('change', fileSelectHandler, false);
		}

		function fileDragHover(e) {
			e.preventDefault();
			fileDrag.className = (e.type === 'dragover' ? 'hover' : '');
		}
	
		function fileSelectHandler(e) {
			fileDragHover(e);
	
			const files = e.target.files || e.dataTransfer.files;
			const file = files[0];
			const isGood = (/\.(?=csv)/gi).test(file.name);
	
			if (!isGood) {
				fileText.innerHTML = 'Tipo de archivo no válido';
				return;
			}
	
			if (fileText && file) {
				fileText.innerHTML = `<p>${file.name}<p>`;

				const reader = new FileReader();
	
				reader.readAsText(file);
	
				reader.onload = () => {
					const contents = reader.result;
					convertToJSON(contents);
				}
			}
		}
	}, []);

	function convertToJSON(csv) {
		const array = csv.toString().replace("\r", "").split("\n");
		let result = [];
		let headers = array[0].split(",");

		for(let index=1; index <= array.length -1; index++) {
			let obj = {}
			let str = array[index]
			let s = '';
			let flag = 0;

			for (let ch of str) {
				if (ch === '"' && flag === 0) {
					flag = 1
				}
				else if (ch === '"' && flag === 1) flag = 0
				if (ch === ',' && flag === 0) ch = '|'
				if (ch !== '"') s += ch
			}

			let properties = s.split("|");

			for (let j in headers) {
				if (properties[j].includes(",")) {
				obj[headers[j]] = properties[j]
					.split(", ").map(item => item.trim())
				}
				else obj[headers[j]] = properties[j]
			}

			result.push(obj);
		}

		if(props.setData) {
			props.setData(result);
		}
	}

	return (
		<div className="input-drop-file">
			<label htmlFor="file-upload" id="file-drag" className="">
				<div className="title-drop-file" id="file-text">
					<i className="fas fa-upload"></i>
					<p>Arrastra tu archivo aquí para cargarlo</p>
				</div>
			</label>
			<input className="hidden" id="file-upload" type="file" name="fileUpload" accept=".csv" />
		</div>
	)
}

export default DropFile;
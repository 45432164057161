import React, { useEffect } from 'react';

const DropImage = (props) => {
	useEffect(() => {
		const fileDrag		= document.getElementById('image-drag');
		const fileSelect	= document.getElementById('image-upload');
		const fileText		= document.getElementById('image-text');

		if (fileDrag) {
			fileDrag.addEventListener('dragover', fileDragHover, false);
			fileDrag.addEventListener('dragleave', fileDragHover, false);
			fileDrag.addEventListener('drop', fileSelectHandler, false);
		}
	
		if (fileSelect) {
			fileSelect.addEventListener('change', fileSelectHandler, false);
		}

		function fileDragHover(e) {
			e.preventDefault();
			fileDrag.className = (e.type === 'dragover' ? 'hover' : '');
		}
	
		async function fileSelectHandler(e) {
			if(props.setLoading) {
				props.setLoading(true);
			}

			fileDragHover(e);

			const files = e.target.files || e.dataTransfer.files;
			const file = files[0];
			if(file) {
				const isGood = (/\.(?=gif|jpg|png|jpeg)/gi).test(file.name);
		
				if (!isGood) {
					fileText.innerHTML = 'Tipo de archivo no válido';
					return;
				}
	
				if (fileText && file) {
					//fileText.innerHTML = `<p>${file.name}<p>`;

					const reader = new FileReader();
		
					reader.readAsDataURL(file);
		
					reader.onload = () => {
						const contents = reader.result;
					}

					if(props.setData) {
						props.setData({file});
					}
					if(props.setLoading) {
						props.setLoading(false);
					}

					fileSelect.value = null;
					fileDrag.value = null;
				}
			}
		}
	}, []);

	return (
		<div className="input-drop-image">
			<label htmlFor="image-upload" id="image-drag" className="">
				<div className="title-drop-image" id="image-text">
					<i className="fas fa-upload"></i>
					<p>Selecciona o Arrastra tu imagen aquí para cargarla</p>
				</div>
			</label>
			{/*props.info.nombre*/}
			{props.children}
			<input className="hidden" id="image-upload" type="file" name="imageUpload" accept="image/*" />
		</div>
	)
}

export default DropImage;